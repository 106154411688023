<template>
  <span>
    <section>
      <div class="columns" style="padding-left: 15%; padding-top:60px; padding-bottom:0; text-align: center">
        <div class="column is-3" style="padding-top: 10px;">@ Copyright 2021-2023</div>
        <div class="column is-2" style="padding-top: 10px;"><a href="mailto:tikfollowers@gmail.com"><b>{{ $t('bottom.Contact US') }}</b></a></div>
        <div class="column is-2" style="padding-top: 10px;"><a href="/privacy.html"><b>{{ $t('bottom.Privacy policy') }}</b></a></div>
        <div class="column is-2" style="padding-top: 10px;"><a href="/sitemap"><b>{{ $t('bottom.Sitemap') }}</b></a></div>
      </div>
    </section>
  </span>
</template>

<script>
export default {
  name: "bottom"
}
</script>

<style scoped>
.containerA {
  min-height: 40px;
  /*background: #fafafa;*/
  border-radius: 10px;
  display:flex;/*实现垂直居中*/
  align-items:center;/*实现水平居中*/
  justify-content:center;
  text-align:justify;
}
</style>